import axios from "axios";
import endpoints from "../utils/endpoints";
import { accessToken } from "../utils/token";

const postRedeemTicket = async (params: any) => {
  const url = endpoints.REDEEM;

  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const postRedeemTicketByName = async (params: any) => {
  const url = endpoints.REDEEM + "/by-name";

  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const postCheckIn = async (params: any) => {
  const url = endpoints.CHECKIN;

  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const postCheckOut = async (params: any) => {
  const url = endpoints.CHECKOUT;

  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const service = {
  postRedeemTicket,
  postCheckIn,
  postCheckOut,
  postRedeemTicketByName,
}

export default service;
